
// import userInfoService from "../../services/userInfoService";
import userGateWayStatusService from "../../services/userGateWayStatusService";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../store/types";

export default {
  name: 'dashboard-merchant-info',
  components: {},
  props: [],
  data() {
    return {
      IPAddress: "",
      merchantID: localStorage.getItem("bitrahAccessToken"),
      merchantSlug: localStorage.getItem("bitrahAccessToken"),
    }
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
      userGateway: types.GET_USER_GATEWAY_STATUS,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.getUserGatewayStatusData();
      }, 2300);
    // this.getUserGatewayStatusData();

  },
  created() {
    // this.getInfoData();

  },
  methods: {
    ...mapActions({
      // userInfoAction: types.ACTION_USER_INFO,
      userGateWayAction: types.ACTION_USER_GATEWAY_STATUS,
    }),
  
    
    getUserGatewayStatusData() {
      userGateWayStatusService
        .getUserGateWay(this.userInfo.username, this.IPAddress)
        .then(response => {
          this.userGateWayAction(response.data);
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false,
          });
        })
        .finally(() => (this.loading = false));
    },
    // getInfoData() {
    //   userInfoService
    //     .getUserInfo()
    //     .then((response) => {
    //       this.userInfoAction(response.data);
    //     })
    //     .catch((error) => {
    //       this.$bvToast.toast(error.response.data.message, {
    //         title: "خطا",
    //         noCloseButton: true,
    //         variant: "custom",
    //         bodyClass:
    //           "dangerBackgroundColor dangerFontColor rtl text-right",
    //         headerClass:
    //           "dangerBackgroundColor dangerFontColor rtl text-right",
    //         solid: false,
    //       });
    //     })
    //     .finally(() => (this.loading = false));
    // },
  },
}


